import HomeDesktop from './pages/HomeDesktop';
import { BrowserView, MobileView } from 'react-device-detect';
import HomeMobile from './pages/HomeMobile';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import OrderMobile from './pages/OrderMobile';
import Membership from './pages/membership';
import ReviewEvent from './pages/reviewEvent';
import CustomerPage from './pages/CustomerPage';
import Subscriptions from './pages/Subscriptions';
import Packages from './pages/Packages';
import Memberships from './pages/Memberships';
import RequireAuth from './components/requireAuth';
import Login from './pages/login';
import ChangePassword from './pages/ChangePassword';
import * as Sentry from "@sentry/react";
import FlaggedAccountNotice from './pages/flaggedAccountNotice';

Sentry.init({
    dsn: "https://ac3e886ebae3d36f1fd7a309213bb4c2@o962230.ingest.sentry.io/4506591133433856",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

const App = () => {

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={
                        <>
                        <BrowserView>
                            <HomeDesktop />
                        </BrowserView>
                        <MobileView>
                            <HomeMobile />
                        </MobileView>
                        </>
                    }></Route>
                    <Route path='/membership' element={<Membership />} />
                    <Route path='/review-event' element={<ReviewEvent />} />
                    <Route path='/notice/flagged-account' element={<FlaggedAccountNotice />} />
                    <Route path='/order/:orderId' element={<OrderMobile />} />
                    <Route path="/customers/:customerId" element={<RequireAuth><Sentry.ErrorBoundary><CustomerPage /></Sentry.ErrorBoundary></RequireAuth>} />
                    <Route path="/customers/:customerId/subscriptions/:subscriptionId" element={<RequireAuth><Sentry.ErrorBoundary><Subscriptions /></Sentry.ErrorBoundary></RequireAuth>} />
                    <Route path="/customers/:customerId/packages/:packageId" element={<RequireAuth><Sentry.ErrorBoundary><Packages /></Sentry.ErrorBoundary></RequireAuth>} />
                    <Route path="/customers/:customerId/memberships/:membershipId" element={<RequireAuth><Sentry.ErrorBoundary><Memberships /></Sentry.ErrorBoundary></RequireAuth>} />
                    <Route path="/login" element={<Sentry.ErrorBoundary><Login /></Sentry.ErrorBoundary>} />
                    <Route path="/customers/:customerId/change-password" element={<RequireAuth><Sentry.ErrorBoundary><ChangePassword /></Sentry.ErrorBoundary></RequireAuth>} />
                    <Route path='*' element={
                        <>
                        <div style={{ height: 200, textAlign: 'center', paddingTop: 50 }}>잘못된 링크입니다.<br/>고객센터로 문의해주세요</div>
                        </>
                    }></Route>
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;