import Cookies from 'js-cookie';
import { AUTH_TOKEN_EXPIRATION_DATE } from './constants';
import getCookiesCustomerId from './utils/getCookiesCustomerId';

export const getAuthToken = (customerId: string) => {
    const tokenDataString = Cookies.get(getCookiesCustomerId(customerId));
    if (tokenDataString) {
        try {
            const tokenData = JSON.parse(tokenDataString);
            const expirationDate = new Date(tokenData.expires);
            if (expirationDate < new Date()) {
                clearAuthCookie(customerId);
                return null;
            }

            return {
                token: tokenData.token,
                expires: expirationDate
            };
        } catch (err) {
            clearAuthCookie(customerId);
            return null;
        }
    }

    return null;
};

export const setAuthCookie = (token: string, customerId: string) => {
    const expirationDate = new Date();
    expirationDate.setMilliseconds(expirationDate.getMilliseconds() + AUTH_TOKEN_EXPIRATION_DATE);


    const tokenData = {
        token,
        expires: expirationDate.toISOString(),
    };

    Cookies.set(getCookiesCustomerId(customerId), JSON.stringify(tokenData));
};

export const clearAuthCookie = (customerId: string) => {
    Cookies.remove(getCookiesCustomerId(customerId));
};