import styled from "styled-components";
import { IProductDisplayList } from "../utils/productList";

const ProductBox = styled.div`
display: grid;
grid-template-columns: repeat(4, 1fr);
padding: 0 15px 15px 15px;
gap: 5px;
@media screen and (min-width: 600px) {
    grid-template-columns: repeat(5, 1fr);
}
`;

interface IProductDisplay {
    list: Array<IProductDisplayList>;
};

export const ProductDisplay = (props: IProductDisplay) => {
    return (
        <ProductBox>
            { props.list && props.list.map(d => {
                const regexPattern = /\/([^/]+)\./;
                const match = d.src.match(regexPattern);
                const altText = match ? match[1] : '';
                return (
                    <a
                        href={`${d.url}`}
                        target='_blank'
                        rel='noreferrer'
                        key={d.url}
                    >
                        <img
                            src={`${d.src}`}
                            width='100%'
                            alt={altText}
                        />
                    </a>
                )
            })}
        </ProductBox>
    )
};

export default ProductDisplay;