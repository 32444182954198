import axios from "axios";
import { ChangeCustomerPasswordDto, CustomerDto, CustomerLoginDto, CustomerPurchaseItemDetailDto, CustomerPurchaseItemDto, OrderTaskDto, ResponseLoginCustomerDto } from "./apiTypes";
import axiosInstance from "./axiosInstance";

class Repository {
    url = '/insta-leader';

    async postCustomerLogin(payload: CustomerLoginDto): Promise<ResponseLoginCustomerDto> {
        const response = await axiosInstance.post(`${this.url}/customers/login`, payload);
        return response.data;
    };

    async changePassword(newPassword: ChangeCustomerPasswordDto) {
        const response = await axiosInstance.put(`${this.url}/customers/password`, newPassword);
        return response.data;
    };

    async getMe(): Promise<CustomerDto> {
        const response = await axiosInstance.get(`${this.url}/customers/me`);
        return response.data;
    };

    async getInProgressList(customerId: string): Promise<Array<CustomerPurchaseItemDto>> {
        const response = await axiosInstance.get(`${this.url}/customers/${customerId}/in-progress`);
        return response.data;
    };

    async getCompletedList(customerId: string): Promise<Array<CustomerPurchaseItemDto>> {
        const response = await axiosInstance.get(`${this.url}/customers/${customerId}/completed`);
        return response.data;
    };

    async getSubscriptionOrder(customerId: string, subscriptionOrderId: string): Promise<CustomerPurchaseItemDetailDto> {
        const response = await axiosInstance.get(`${this.url}/customers/${customerId}/subscriptionOrder/${subscriptionOrderId}`)
        return response.data;
    };

    async getSubscriptionTasks(customerId: string, subscriptionOrderId: string): Promise<Array<OrderTaskDto>> {
        const response = await axiosInstance.get(`${this.url}/customers/${customerId}/subscriptionOrder/${subscriptionOrderId}/tasks`)
        return response.data;
    };

    async getMembershipOrder(customerId: string, membershipId: string): Promise<CustomerPurchaseItemDetailDto> {
        const response = await axiosInstance.get(`${this.url}/customers/${customerId}/memberships/${membershipId}`)
        return response.data;
    };

    async getMembershipTasks(customerId: string, membershipId: string): Promise<Array<OrderTaskDto>> {
        const response = await axiosInstance.get(`${this.url}/customers/${customerId}/memberships/${membershipId}/tasks`)
        return response.data;
    };
}

const repository = new Repository();
export default repository;