import { createGlobalStyle } from 'styled-components';
import { isMobile } from 'react-device-detect';

const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'NanumSquareLight';
        font-family: 'NanumSquare';
        font-family: 'NanumSquareBold';
        font-family: 'NanumSquareExtraBold';
        font-family: 'NanumSquareAcb';
        font-family: 'NanumSquareAceb';
        font-family: 'NanumSquareAcl';
        font-family: 'NanumSquareAcr';
    }
    body {
        padding: 0;
        margin: 0;
        font-size: ${isMobile ? 14 : 18}px;
        line-height: 1.4em;
    };

    a {
        text-decoration: none;
        color: inherit;
    };

    li {
        list-style: none;
    };

    button {
        cursor: pointer;
        background: inherit;
        border: none;
        box-shadow: none;
        border-radius: 0;
        padding:0;
        overflow: visible;
    };
`;

export default GlobalStyle;