import styled from "styled-components";
import DropShadow from "./dropShadow";

const Header = styled(DropShadow)`
box-sizing: border-box;
position: fixed;
top: 0;
width: -moz-available;
width: -webkit-fill-available;
width: fill-available;
max-width: 600px;
display: flex;
flex-direction: row;
align-items: stretch;
justify-content: space-between;
height: 74px;
z-index: 100;
`;

export default Header;