import styled from "styled-components";

const OrderListTop = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0 20px;
margin-top: 20px;
`;

const OrderListTopLeft = styled.div`
font-size: ${({ theme }) => theme.typography.size.m1}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
color: ${({ theme }) => theme.colors.black[100]};
`;

const OrderListTopRight = styled.div`
display: flex;
flex-direction: row;
align-items: center;
font-size: ${({ theme }) => theme.typography.size.s2}px;
color: ${({ theme }) => theme.colors.black[100]};
cursor: pointer;
user-select: none;
`;

const OrderListTopRightImg = styled.img<{ descendingFilter?: boolean }>`
margin-left: 4px;
transform: ${({ descendingFilter }) => descendingFilter ? `rotate(180deg)` : ''};
`;

interface IListTopBar {
    leftText: string;
    onlyLeft?: boolean;
    filterText?: string;
    descendingFilter?: boolean;
    onClick?: () => void;
};

const ListTopBar = (props: IListTopBar) => {
    return (
        <OrderListTop>
            <OrderListTopLeft>{props.leftText}</OrderListTopLeft>
            { props.onlyLeft
                ? <></>
                : <OrderListTopRight
                    onClick={props.onClick}
                >{props.filterText}
                    <OrderListTopRightImg
                        descendingFilter={props.descendingFilter}
                        src={'/images/icons/Arrow.svg'}
                    />
                </OrderListTopRight>
            }
        </OrderListTop>
    )
};

export default ListTopBar;