import styled from "styled-components";

const BoxLinkButton = styled.div<{ grayButton?: boolean }>`
border-radius: 5px;
border: 1px ${({ theme }) => theme.colors.gray[500]} solid;
background-color: ${({ theme, grayButton }) => grayButton ? '#f4f4f4' : theme.colors.white.base};
color: ${({ theme, grayButton }) => grayButton ? theme.colors.black[200] : theme.colors.black[300]};
padding: 11px 0;
text-align: center;
font-size: ${({ theme }) => theme.typography.size.s2}px;
line-height: 16px;
cursor: pointer;
`;

export default BoxLinkButton;