import { useEffect, useState } from "react";
import styled from "styled-components";
import BoxShadow from "./boxShadow";

const DropDownWrapper = styled.div`
`;

const TitleBox = styled.div`
cursor: pointer;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
box-sizing: border-box;
padding: 15px;
height: 45px;
user-select: none;
`;

const LeftText = styled.div`
color: ${({ theme }) => theme.colors.black[100]};
font-size: ${({ theme }) => theme.typography.size.s2}px;
font-weight: 800;
`;

const TitleRight = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

const RightText = styled.div<{ finishedText: boolean }>`
color: ${({ theme, finishedText }) => finishedText ? theme.colors.gray[800] : theme.colors.red.base};
font-size: ${({ theme }) => theme.typography.size.s1}px;
margin-right: 10px;
`;

const TitleDropDownImg = styled.img<{ open: boolean }>`
transform: ${({ open }) =>  open ? 'rotate(180deg)' : ''};
`;

interface IDropDownBox {
    leftText: string;
    finishedText?: boolean;
    children?: React.ReactNode;
    open?: boolean;
};

const DropDownBox = (props: IDropDownBox) => {

    const [ dropDownOpen, setDropDownOpen ] = useState<boolean>(false);

    useEffect(() => {
        if (props.open) {
            setDropDownOpen(true);
        }
    }, [props.open]);

    return (
        <BoxShadow>
            <DropDownWrapper>
                <TitleBox
                    onClick={() => {
                        setDropDownOpen(prev => !prev);
                    }}
                >
                    <LeftText>{props.leftText}</LeftText>
                    <TitleRight>
                        { props.finishedText !== undefined &&
                            <RightText finishedText={props.finishedText}>{props.finishedText ? '완료' : '진행 중' }</RightText>
                        }
                        <TitleDropDownImg open={dropDownOpen}
                            src={'/images/icons/DownLine.svg'}
                        />
                    </TitleRight>
                </TitleBox>
                { dropDownOpen ? props.children : <></> }
            </DropDownWrapper>
        </BoxShadow>
    );
};

export default DropDownBox;