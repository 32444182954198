import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = () => keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const ToTheTopBox = styled.button<{ scrollTop: boolean }>`
animation: ${({ scrollTop }) => scrollTop ? fadeIn : ''} 1s;
-moz-animation: ${({ scrollTop }) => scrollTop ? fadeIn : ''} 1s;
-webkit-animation: ${({ scrollTop }) => scrollTop ? fadeIn : ''} 1s;
-o-animation: ${({ scrollTop }) => scrollTop ? fadeIn : ''} 1s;
display: ${({ scrollTop }) => scrollTop ? '' : 'none'};
position: fixed;
bottom: 30px;
left: 50%;
transform: translate(-50%, 0);
box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.10);
background-color: ${({ theme }) => theme.colors.white.base};
border-radius: 30px;
padding: 10px 10px 8px 10px;
z-index: 100;
`;

const ToTheTopImg = styled.img`
width: 16px;
height: 16px;
`;

const ToTheTopButton = () => {
    const [showButton, setShowButton] = useState<boolean>(false);

    const throttle = (callback: any, delay: number) => {
        let timer: any;
        return (event: any) => {
          // 타이머가 호출되면, 함수를 실행하고 타이머 제거
            if (timer) return;
            timer = setTimeout(() => {
                callback(event);
                timer = null;
            }, delay);
        };
    };

    const scrollEvent = () => {
        const scrollThreshold = document.documentElement.clientHeight;
        if (!showButton && window.scrollY > scrollThreshold){
            setShowButton(true);
            return;
        }
        if (showButton && window.scrollY < scrollThreshold) {
            setShowButton(false);
        }
    };

    useEffect(() => {
        const scrollFunc = throttle(scrollEvent, 500);
        window.addEventListener('scroll', scrollFunc);
        return () => window.removeEventListener('scroll', scrollFunc);
    }, [ showButton ]);

    return (
        <>
        <ToTheTopBox
            scrollTop={showButton}
            onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
        >
            <ToTheTopImg
                src={'/images/icons/ToTheTop.svg'}
            />
        </ToTheTopBox>
        </>
    )
};
export default ToTheTopButton;