import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import BoxLinkButton from "../components/boxLinkButton";
import DropDownBox from "../components/dropDownBox";
import Header from "../components/header";
import ListTopBar from "../components/listTopBar";
import WrapperV2 from "../components/wrapperV2";
import { Link, useLocation } from 'react-router-dom';
import { CustomerPurchaseItemDetailDto, OrderTaskDto } from "../apiTypes";
import repository from "../repository";
import DropDownTaskItem from "../components/dropDownTaskItem";
import ProductDisplay from "../components/productDisplay";
import { AutoLikeProduct, ForeignerLikeFollowProduct, KoreanLikeFollowProduct, PackageSubscriptionProduct } from "../utils/productList";
import Mask from "../components/Mask";
import getCustomerIdFromPath from "../utils/getCustomerIdFromPath";
import getSubscriptionIdFromPath from "../utils/getSubscriptionIdFromPath";
import ToTheTopButton from "../components/toTheTopButton";

enum ITaskStatus {
    Finished = 'finished',
    PendingPromotion = 'pending_promotion',
    Impossible = 'impossible',
    Locked = 'locked',
    NotFinished = 'not_finished',
    TemporaryPaused ='temporary_paused',
    WaitExternalVendor = 'wait_external_vendor'
};

enum SubscriptionDtoStatusTypeEnum {
    Finished = 'finished',
    Starting = 'starting',
    Canceled = 'canceled',
    Pending = 'pending'
};

const HeaderRightContent = styled.div`
font-size: ${({ theme }) => theme.typography.size.m1}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
line-height: 18px;
display: flex;
align-items: center;
text-align: left;
flex: 1;
padding: 0 25px 0 0;
`;

const HeaderLeftContent = styled.img`
width: 20px;
height: 20px;
`;

const BriefInfoSection = styled.section`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
box-sizing: border-box;
background-color: ${({ theme }) => theme.colors.white.base};
padding: 20px;
margin-top: 35px;
`;

const BriefInfoBlackText = styled.span`
color: ${({ theme }) => theme.colors.black[200]};
font-size: ${({ theme }) => theme.typography.size.m2}px;
`;

const BriefInfoRedText = styled.span`
color: ${({ theme }) => theme.colors.red.base};
font-size: ${({ theme }) => theme.typography.size.m2}px;
font-weight: 800;
`;

const PendingInfoText = styled.div`
color: ${({ theme }) => theme.colors.gray.base};
font-size: ${({ theme }) => theme.typography.size.s1}px;
`;

const BasicInfoSection = styled.section`
display: flex;
flex-direction: column;
background-color: ${({ theme }) => theme.colors.white.base};
padding: 20px;
margin-top: 15px;
`;

const OrderNumberText = styled.div`
color: ${({ theme }) => theme.colors.black[300]};
font-size: ${({ theme }) => theme.typography.size.s2}px;
line-height: 16px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
`;

const OrderNumberTitle = styled.span`
color: ${({ theme }) => theme.colors.black.base};
font-size: ${({ theme }) => theme.typography.size.s2}px;
line-height: 16px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
`;

const HorizontalLine = styled.div`
margin: 18px 0;
height: 1px;
background-color: ${({ theme }) => theme.colors.gray[500]};
`;

const BasicInfoTimeBox = styled.div`
display: flex;
flex-direction: column;
gap: 13px;
`;

const BasicInfoTimeBoxLine = styled.div`
display: flex;
`;

const BasicInfoGrayTitle = styled.div`
font-size: ${({ theme }) => theme.typography.size.s2}px;
color: ${({ theme }) => theme.colors.gray[800]};
line-height: 16px;
margin-right: 10px;
`;

const BasicInfoTimeText = styled.div`
font-size: ${({ theme }) => theme.typography.size.s2}px;
color: ${({ theme }) => theme.colors.black[200]};
line-height: 16px;
`;

const BasicInfoPriceBox = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-bottom: 25px;
`;

const BasicInfoPriceText = styled.div`
font-size: ${({ theme }) => theme.typography.size.s2}px;
color: ${({ theme }) => theme.colors.black[200]};
font-weight: ${({ theme }) => theme.typography.weight.bold};
line-height: 16px;
`

const BasicInfoButtonBox = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
`;

const RightArrowImg = styled.img`
width: 6px;
height: 8px;
margin-left: 8px;
color: ${({ theme }) => theme.colors.black[100]};
`;

const OrderProcessListSection = styled.section`
`;

const ShoppingSection = styled.section`
margin-bottom: 100px;
`;

const DropDownBoxWrapper = styled.div`
padding: 10px 20px;
`;

const DropDownBoxProductWrapper = styled.div`
display: flex;
flex-direction: column;
padding: 10px 20px;
gap: 10px;
`;

function subscriptionStatus(status: SubscriptionDtoStatusTypeEnum | '') {
    if (status === '') {
        return false;
    }
    if (status === SubscriptionDtoStatusTypeEnum.Canceled) {
        return '취소';
    }
    if (status === SubscriptionDtoStatusTypeEnum.Pending) {
        return '일시정지';
    }
    return false;
};

function nowDate (startDate: string, totalRound: number) {
    if (startDate === '' || totalRound === 0) {
        return '-';
    }
    const startDate_ = moment(startDate).format('YYYY-MM-DD');
    const todayDate = moment().format('YYYY-MM-DD');
    const nowDate = moment(todayDate).diff(moment(startDate_), 'days') + 1;
    if (totalRound < nowDate) {
        return totalRound;
    }
    return nowDate;
};

function detectedDateDiffStartDate (startDate: string, endDate: string, detectedDate: string) {
    const startDate_ = moment(startDate).format('YYYY-MM-DD');
    const endDate_ = moment(endDate).format('YYYY-MM-DD');
    const diffDays = moment(detectedDate).diff(moment(startDate_), 'days') + 1;
    // detectedDate가 startDate보다 빠른 경우
    if (diffDays < 1) {
        return 1;
    }
    // detectedDate가 endDate보다 늦은 경우
    if (moment(detectedDate).isAfter(endDate_)) {
        return moment(endDate_).diff(moment(startDate_), 'days') + 1
    }
    return diffDays;
};

const Subscriptions = () => {
    const url = useLocation();
    const [ descendingFilter, setDescendingFilter ] = useState<boolean>(true);
    const [ subscriptionDetail, setSubscriptionDetail ] = useState<CustomerPurchaseItemDetailDto>();
    const [ rawTaskList, setRawTaskList ] = useState<Array<OrderTaskDto>>([]);
    const [ taskList, setTaskList ] = useState<Array<Array<OrderTaskDto>>>([]);
    const [ loading, setLoading ] = useState<boolean>(true);

    const taskListSorting = useCallback((list: Array<OrderTaskDto>, descend: boolean, info: CustomerPurchaseItemDetailDto) => {
        if (!info || !info.subscriptions[0] || !info.purchaseDate || list.length === 0) {
            return [];
        }

        const len = Math.round(moment.duration(moment(info.subscriptions[0].endDate).diff(moment(info.purchaseDate))).asDays() + 1);
        let dateArray: Array<string> = [];
        for (let i = 0; i < len; i++) {
            dateArray.push(moment(info.purchaseDate).add(i, 'days').format('YYYY-MM-DD'))
        }

        let sortedArray: Array<Array<OrderTaskDto>> = dateArray.map(d => []);
        list.forEach((item) => {
            const detectedDateIndex = dateArray.findIndex(date => moment(item.detectedDate).isSame(date, 'day'));
            if (detectedDateIndex !== -1) {
                sortedArray[detectedDateIndex].push(item);
                return;
            }
            if (moment(item.detectedDate).isBefore(info.subscriptions[0].startDate,'day')) {
                sortedArray[0].push(item);
                return;
            }
            if (moment(item.detectedDate).isAfter(info.subscriptions[0].endDate,'day')) {
                sortedArray[sortedArray.length -1].push(item);
                return;
            }
        });
        const coupledList = sortedArray.filter(d => d.length > 0);
        const innerSortedList = coupledList.map(d => {
            if (d.length > 1) {
                d.sort((a: OrderTaskDto, b: OrderTaskDto) => {
                    return moment(a.createdAt).isBefore(moment(b.createdAt)) ? 1
                        : moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 0
                })
            }
            return d;
        })
        if (descend) {
            return innerSortedList.reverse();
        }
        return innerSortedList;
    }, [ ]);

    const sortByFilter = useCallback((filterState: boolean) => {
        if (!rawTaskList || rawTaskList.length === 0 || !subscriptionDetail) {
            return;
        }
        if (filterState === true) {
            setTaskList(taskListSorting(rawTaskList, true, subscriptionDetail));
            setDescendingFilter(true);
            return;
        }
        setTaskList(taskListSorting(rawTaskList, false, subscriptionDetail));
        setDescendingFilter(false);
        return;
    }, [ rawTaskList, taskListSorting, subscriptionDetail ]);

    const getSubscriptionOrder = useCallback(async (id: string, subscriptionId: string) => {
        const subscriptionDetailRs = await repository.getSubscriptionOrder(id, subscriptionId);
        if (subscriptionDetailRs) {
            setSubscriptionDetail(subscriptionDetailRs);
            return subscriptionDetailRs;
        }
    }, [ ]);

    const getSubscriptionTasks = useCallback(async (id: string, subscriptionId: string, info: CustomerPurchaseItemDetailDto) => {
        const subscriptionTasks = await repository.getSubscriptionTasks(id, subscriptionId);
        if (subscriptionTasks) {
            setRawTaskList(subscriptionTasks);
            setTaskList(taskListSorting(subscriptionTasks, true, info));
        }
    }, [ taskListSorting ]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!url || !url.pathname) {
            return;
        }
        ( async () => {
            try {
                setLoading(true);
                const customerId = getCustomerIdFromPath(url.pathname);
                const subscriptionId = getSubscriptionIdFromPath(url.pathname);
                const rs = await getSubscriptionOrder(customerId, subscriptionId);
                if (rs) {
                    await getSubscriptionTasks(customerId, subscriptionId, rs);
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
            };
        })();
    }, [ url, getSubscriptionOrder, getSubscriptionTasks ]);

    return (
        <WrapperV2>
            <main>
            <Header>
                <Link
                    to={`/customers/${getCustomerIdFromPath(url.pathname)}`}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 70 }}
                >
                    <HeaderLeftContent
                        src={'/images/icons/Home.svg'}
                    />
                </Link>
                <HeaderRightContent>
                    {subscriptionDetail?.productName
                        ? subscriptionDetail.subscriptions.find(d => d.count > 1)
                            ? `${subscriptionDetail?.productName} ${subscriptionDetail.subscriptions.find(d => d.count > 1)?.count}개`
                            : subscriptionDetail.productName
                        : '-'
                    }
                </HeaderRightContent>
            </Header>
            { loading
                ? <Mask />
                : <>
                    <BriefInfoSection>
                        <div>
                            <BriefInfoRedText>{`${nowDate(subscriptionDetail?.subscriptions[0].startDate ?? '', subscriptionDetail?.subscriptions[0].totalRound ?? 0)}일차`}</BriefInfoRedText>
                            <BriefInfoBlackText>{` / ${subscriptionDetail?.subscriptions[0].totalRound ?? '-'}일`}</BriefInfoBlackText>
                        </div>
                        { subscriptionStatus(subscriptionDetail?.subscriptions[0].statusType ?? '') &&
                            <PendingInfoText>{`구독이 ${subscriptionStatus(subscriptionDetail?.subscriptions[0].statusType ?? '')}된 상태입니다.`}</PendingInfoText>
                        }
                    </BriefInfoSection>
                    <BasicInfoSection>
                        <OrderNumberText>
                            <OrderNumberTitle>주문번호 </OrderNumberTitle>
                            {subscriptionDetail?.orderNo ?? '-'}
                        </OrderNumberText>
                        <HorizontalLine/>
                        <BasicInfoTimeBox>
                            <BasicInfoTimeBoxLine>
                                <BasicInfoGrayTitle>주문시간</BasicInfoGrayTitle>
                                <BasicInfoTimeText>
                                    { subscriptionDetail?.purchaseDate
                                        ? moment(subscriptionDetail?.purchaseDate).format('YYYY. MM. DD(dd) a h:mm')
                                        : '-'
                                    }
                                </BasicInfoTimeText>
                            </BasicInfoTimeBoxLine>
                            <BasicInfoTimeBoxLine>
                                <BasicInfoGrayTitle>완료기한</BasicInfoGrayTitle>
                                <BasicInfoTimeText>
                                    { subscriptionDetail?.subscriptions[0].endDate
                                        ? moment(subscriptionDetail?.subscriptions[0].endDate).format('YYYY. MM. DD(dd) a h:mm')
                                        : '-'
                                    }
                                </BasicInfoTimeText>
                            </BasicInfoTimeBoxLine>
                            { subscriptionDetail?.subscriptions[0].statusType === 'pending' &&
                                <BasicInfoTimeBoxLine>
                                    <BasicInfoGrayTitle>정지시간</BasicInfoGrayTitle>
                                    <BasicInfoTimeText>
                                        { subscriptionDetail?.subscriptions[0]?.pendingDt
                                            ? moment(subscriptionDetail.subscriptions[0].pendingDt).format('YYYY. MM. DD(dd) a h:mm')
                                            : '-'
                                        }
                                    </BasicInfoTimeText>
                                </BasicInfoTimeBoxLine>
                            }
                        </BasicInfoTimeBox>
                        <HorizontalLine/>
                        <BasicInfoPriceBox>
                            <BasicInfoGrayTitle>결제금액</BasicInfoGrayTitle>
                            <BasicInfoPriceText>{`${subscriptionDetail?.paymentAmount.toLocaleString() ?? '-'}원`}</BasicInfoPriceText>
                        </BasicInfoPriceBox>
                        <BasicInfoButtonBox>
                            <BoxLinkButton grayButton onClick={() => {
                                window.open('/membership', '_blank', 'noreferrer');
                            }}>
                                멤버십 등급제 시작 안내<RightArrowImg src={'/images/icons/RightLine.svg'} />
                            </BoxLinkButton>
                            <BoxLinkButton grayButton onClick={() => {
                                window.open('/review-event', '_blank', 'noreferrer');
                            }}>
                                리뷰 적립금 이벤트 개편 안내<RightArrowImg src={'/images/icons/RightLine.svg'} />
                            </BoxLinkButton>
                        </BasicInfoButtonBox>
                    </BasicInfoSection>
                    <OrderProcessListSection>
                        <ListTopBar
                            leftText='작업 진행 내역'
                            filterText='작업일자'
                            descendingFilter={descendingFilter}
                            onClick={() => {
                                sortByFilter(!descendingFilter);
                            }}
                        />
                        { (taskList && taskList.length > 0)
                            ? taskList.map((dayData, index) => (
                                <DropDownBoxWrapper
                                    key={index}
                                >
                                    <DropDownBox
                                        leftText={`${detectedDateDiffStartDate(subscriptionDetail?.subscriptions[0].startDate ?? '', subscriptionDetail?.subscriptions[0].endDate ?? '', dayData[0].detectedDate)}일차`}
                                        finishedText={dayData.findIndex(d => d.taskStatus !== ITaskStatus.Finished) === -1}
                                        open={index === 0}
                                    >
                                        <DropDownTaskItem
                                            itemList={dayData}
                                        />
                                    </DropDownBox>
                                </DropDownBoxWrapper>
                            )) : <div style={{ padding: '10px 20px' }}>내역이 없습니다.</div>
                        }
                    </OrderProcessListSection>
                    <ShoppingSection>
                        <ListTopBar
                            leftText='상품 둘러보기'
                            onlyLeft
                        />
                        <DropDownBoxProductWrapper>
                            <DropDownBox
                                leftText='패키지 구독 ⭐'
                                open={true}
                            >
                                <ProductDisplay
                                    list={PackageSubscriptionProduct}
                                />
                            </DropDownBox>
                            <DropDownBox
                                leftText='자동 좋아요'
                            >
                                <ProductDisplay
                                    list={AutoLikeProduct}
                                />
                            </DropDownBox>
                            <DropDownBox
                                leftText='한국인 좋아요 팔로우'
                            >
                                <ProductDisplay
                                    list={KoreanLikeFollowProduct}
                                />
                            </DropDownBox>
                            <DropDownBox
                                leftText='외국인 좋아요 팔로우'
                            >
                                <ProductDisplay
                                    list={ForeignerLikeFollowProduct}
                                />
                            </DropDownBox>
                        </DropDownBoxProductWrapper>
                    </ShoppingSection>
                    <ToTheTopButton/>
                </>
                }
            </main>
        </WrapperV2>
    );
};

export default Subscriptions;