interface IGray {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    base: string;
};

interface IWhite {
    base: string;
};

interface IBlack {
    100: string;
    200: string;
    300: string;
    base: string;
};

interface IRed {
    base: string;
};

interface IPrimary {
    100: string;
    200: string;
    300: string;
    base: string;
    400: string;
    500: string;
    600: string;
};

const gray: IGray = {
    100: '#E9E9E9',
    200: '#F3F3F3',
    300: '#EFEFEF',
    400: '#DBDBDB',
    500: '#C4C4C4',
    600: '#A6A6A6',
    base: '#9C9C9C',
    700: '#969696',
    800: '#808080',
};

const white: IWhite = {
    base: '#ffffff',
};

const black: IBlack = {
    100: '#000000',
    200: '#222222',
    base: '#202020',
    300: '#555555',
};

const red: IRed = {
    base: '#FF5358',
};

const primaries: IPrimary = {
    100: '#FFF6EE',
    200: '#FFEEDF',
    300: '#FCDFC6',
    base: '#87ceeb',
    400: '#F16F00',
    500: '#E66C04',
    600: '#D26202',
};


const colors = {
    white,
    gray,
    black,
    red,
    primaries,
};

const palette = {
    colors,
};

export default palette;