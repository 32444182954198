import axios from 'axios';
import { clearAuthCookie, getAuthToken } from './authManager';
import getCustomerIdFromPath from './utils/getCustomerIdFromPath';

const createInstance = () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_HOST,
        headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
        },
    });

	instance.interceptors.request.use(
        async (config) => {
            const customerId = getCustomerIdFromPath(document.location.pathname);
            const authTokenData = getAuthToken(customerId);
            if (config.headers && authTokenData?.token) {
                // @ts-ignore
                config.headers['Authorization'] = `Bearer ${authTokenData?.token}`;
            }

            return config;
		},
		(error) =>  {
			return Promise.reject(error);
		},
	);

	instance.interceptors.response.use(
        (response) => {
			return response;
		},
		(error) => {
            if (error?.response?.status === 403) {
                const customerId = getCustomerIdFromPath(document.location.pathname);
                clearAuthCookie(customerId);
            }
			return Promise.reject(error);
		},
    );

	return instance;
};

const axiosInstance = createInstance();
export default axiosInstance;