import moment from "moment";
import 'moment/locale/ko';
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CustomerPurchaseItemDto, CustomerPurchaseItemProgressDto } from "../apiTypes";
import BoxLinkButton from "./boxLinkButton";
import BoxShadow from "./boxShadow";
import Flex from "./flex";

const OrderItemBox = styled.div`
display: flex;
position: relative;
flex-direction: column;
background-color: ${({ theme }) => theme.colors.white.base};
padding: 15px;
border-radius: 5px;
`;

const TopTitle = styled.div`
color: ${({ theme }) => theme.colors.black[100]};
font-size: ${({ theme }) => theme.typography.size.s2}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
line-height: 16px;
`;

const TopRightImgButton = styled.div`
cursor: pointer;
position: absolute;
top: 0;
right: 0;
padding: 10px 15px 15px 35px;
`;

const TopRightImg = styled.img`
`;

const DateText = styled.div`
color: ${({ theme }) => theme.colors.gray[700]};
font-size: ${({ theme }) => theme.typography.size.s1}px;
line-height: 14px;
`;

const PendingText = styled.div`
color: ${({ theme }) => theme.colors.red.base};
font-size: ${({ theme }) => theme.typography.size.s1}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
line-height: 14px;
margin-top: 6px;
`;

const InstaIdText = styled.div`
color: ${({ theme }) => theme.colors.black[300]};
font-size: ${({ theme }) => theme.typography.size.s1}px;
line-height: 14px;
`;

const DDayText = styled.div`
color: ${({ theme }) => theme.colors.black[100]};
font-size: ${({ theme }) => theme.typography.size.s1}px;
line-height: 14px;
`;

const MembershipLeftCount = styled.div`
color: ${({ theme }) => theme.colors.black[100]};
font-size: ${({ theme }) => theme.typography.size.s1}px;
line-height: 14px;
`;

enum SubscriptionDtoStatusTypeEnum {
    Finished = 'finished',
    Starting = 'starting',
    Canceled = 'canceled',
    Pending = 'pending'
};

interface IMyPageOrderItem {
    item: CustomerPurchaseItemDto;
    to: string;
    completed: boolean;
};

function pendingProgressTaskType (
    itemType: 'package' | 'subscription' | 'membership',
    progressInfo: Array<CustomerPurchaseItemProgressDto>) {
    if (progressInfo.length < 1) {
        return false;
    }
    let pendingList: Array<string> = [];
    const pendingProgress = progressInfo.filter(d => d.statusType === SubscriptionDtoStatusTypeEnum.Pending);
    if (!pendingProgress || pendingProgress.length < 1) {
        return false;
    }
    if (itemType === 'subscription') {
        if (pendingProgress.find(d => d.taskType === 'like')) {
            pendingList = [ ...pendingList, '좋아요'];
        }
        if (pendingProgress.find(d => d.taskType === 'follow')) {
            pendingList = [ ...pendingList, '팔로우'];
        }
    }
    else if (itemType === 'package') {
        if (pendingProgress.find(d => d.taskType === 'like')) {
            pendingList = [ ...pendingList, '좋아요'];
        }
        if (pendingProgress.find(d => d.taskType === 'follow')) {
            pendingList = [ ...pendingList, '팔로우'];
        }
    }
    else if (itemType === 'membership') {
        if (pendingProgress.find(d => d.taskType === 'like')) {
            pendingProgress.forEach(d => {
                if (!pendingList.includes(d.targetId)) {
                    pendingList = [ ...pendingList, d.targetId];
                }
            });
        }
    }
    return pendingList.join(', ');
};

function instaIdFromProgressInfo (progressInfo: Array<CustomerPurchaseItemProgressDto>) {
    if (progressInfo.length < 1) {
        return false;
    }
    let instaIdList: Array<string> = [];
    progressInfo.forEach(d => {
        if (!instaIdList.includes(d.targetId)) {
            instaIdList = [ ...instaIdList, d.targetId];
        }
    });
    return instaIdList.join(', ');
};

function dDayCount (day: number) {
    if (day < 0) {
        return `D${day}`;
    }
    if (day > 0) {
        return `D+${day}`;
    }
    if (day === 0) {
        return '마지막';
    }
};

const MyPageOrderItem = (props: IMyPageOrderItem) => {
    return (
        <BoxShadow>
            <Link to={props.to}>
            <OrderItemBox>
                <Flex
                    justifyContent='space-between'
                >
                    <TopTitle>
                        { props.item?.progressInfo?.find(d => d.count > 1)
                            ? `${props.item.productName} ${props.item.progressInfo.find(d => d.count > 1)?.count}개`
                            : props.item?.productName ?? ''
                        }
                    </TopTitle>
                    <TopRightImgButton>
                        <TopRightImg
                            src={'/images/icons/RightLine.svg'}
                        />
                    </TopRightImgButton>
                </Flex>
                <div style={{ margin: '6px 0' }}>
                    <DateText>
                        {moment(props.item.purchaseDate).format('YY. MM. DD dd | HH:mm:ss')}
                    </DateText>
                    { pendingProgressTaskType(props.item.itemType, props.item.progressInfo ?? []) &&
                        <PendingText>{`${pendingProgressTaskType(props.item.itemType, props.item.progressInfo ?? [])} 일시정지`}</PendingText>
                    }
                </div>
                { props.completed
                    ? <BoxLinkButton
                        grayButton
                        onClick={() => {
                            window.open('/membership', '_blank');
                        }}
                    >
                        리뷰쓰고 적립금받기
                    </BoxLinkButton>
                    : <Flex
                        justifyContent='space-between'
                    >
                        <InstaIdText>
                            {instaIdFromProgressInfo(props.item.progressInfo)}
                        </InstaIdText>
                        { props.item.itemType === 'membership'
                            ? <MembershipLeftCount>{`${props.item.leftCount}회 남음`}</MembershipLeftCount>
                            : <DDayText>{dDayCount(moment(moment()).diff(props.item.endDate, 'days'))}</DDayText>
                        }
                    </Flex>
                }
            </OrderItemBox>
            </Link>
        </BoxShadow>
    )
};

export default MyPageOrderItem;