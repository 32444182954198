import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { getAuthToken } from '../authManager';
import getCustomerIdFromPath from '../utils/getCustomerIdFromPath';
import { useEffect } from 'react';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const tokenData = getAuthToken(getCustomerIdFromPath(document.location.pathname));

    useEffect(() => {
        const tokenData = getAuthToken(getCustomerIdFromPath(document.location.pathname));
        if (!tokenData) {
            navigate('/login', {
                state: {
                    from: location,
                },
                replace: true,
            });
        }
    }, [ location ]);

    if (!tokenData) {
        return (
            <Navigate
                to='/login'
                state={{ from: location }}
                replace
            />
        )
    }

    return children;
};

export default RequireAuth;