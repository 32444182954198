import styled, { keyframes } from "styled-components";

const fillWidth = (length: number) => keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: ${length}%;
    }
`;

const TotalBar = styled.div`
position: relative;
height: 10px;
background-color: ${({ theme }) => theme.colors.gray[300]};
border-radius: 5px;
`;

const ColorBar = styled.div<{ ratio: number }>`
animation: ${({ ratio }) => fillWidth(ratio * 100)} 1.5s;
-moz-animation: ${({ ratio }) => fillWidth(ratio * 100)} 1.5s;
-webkit-animation: ${({ ratio }) => fillWidth(ratio * 100)} 1.5s;
-o-animation: ${({ ratio }) => fillWidth(ratio * 100)} 1.5s;
position: absolute;
top: 0;
left: 0;
bottom: 0;
height: 10px;
width: ${({ ratio }) => `${ratio * 100}%`};
background-color: ${({ theme }) => theme.colors.red.base};
border-radius: 5px;
`;

interface IProgressBar {
    count: number;
    totalCount: number;
};

const ProgressBar = (props: IProgressBar) => {
    return (
        <div>
        <TotalBar>
            <ColorBar ratio={(props.count / props.totalCount) > 1 ? 1 : props.count / props.totalCount}></ColorBar>
        </TotalBar>
        </div>
    )
};

export default ProgressBar;