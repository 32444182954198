import path from 'path';

export function getCustomerId2(pathname: string) {
    const pathname_ = path.relative('/customers', pathname);
    if (pathname_.split('/')) {
        return pathname_.split('/')[0];
    }

    return pathname_;
};

export default function getCustomerIdFromPath(pathname: string) {
    const pathname_ = path.relative('/customers', pathname);
    if (pathname_.split('/')) {
        return pathname_.split('/')[0];
    }

    return pathname_;
};