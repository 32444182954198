import moment from 'moment';

const getOrderData = () => {
    const diffDay = moment().diff(moment('20230327', 'YYYYMMDD'), 'days');
    const onGoing = 460 + diffDay * 2;

    return {
        진행중주문: onGoing,
        완료된주문: 363000 + diffDay * onGoing,
        누적회원수: 9100 + diffDay * 50
    };
};

export default getOrderData;