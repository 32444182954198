import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { CustomerPurchaseItemDetailDto, OrderTaskDto, SubscriptionDto } from "../apiTypes";
import BoxLinkButton from "../components/boxLinkButton";
import DetailPageTaskItem from "../components/detailPageTaskItem";
import DropDownBox from "../components/dropDownBox";
import Flex from "../components/flex";
import Header from "../components/header";
import ListTopBar from "../components/listTopBar";
import Mask from "../components/Mask";
import ProductDisplay from "../components/productDisplay";
import ToTheTopButton from "../components/toTheTopButton";
import WrapperV2 from "../components/wrapperV2";
import repository from "../repository";
import getCustomerIdFromPath from "../utils/getCustomerIdFromPath";
import getMembershipIdFromPath from "../utils/getMembershipIdFromPath";
import { AutoLikeProduct, ForeignerLikeFollowProduct, KoreanLikeFollowProduct, PackageSubscriptionProduct } from "../utils/productList";


const HeaderRightContent = styled.div`
font-size: ${({ theme }) => theme.typography.size.m1}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
line-height: 18px;
display: flex;
align-items: center;
text-align: left;
flex: 1;
padding: 0 25px 0 0;
`;

const HeaderLeftContent = styled.img`
width: 20px;
height: 20px;
`;

const BriefInfoSection = styled.section`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
box-sizing: border-box;
background-color: ${({ theme }) => theme.colors.white.base};
height: 70px;
padding: 20px;
margin-top: 35px;
`;

const BriefInfoBlackText = styled.span`
color: ${({ theme }) => theme.colors.black[200]};
font-size: ${({ theme }) => theme.typography.size.m2}px;
`;

const BriefInfoRedText = styled.span`
color: ${({ theme }) => theme.colors.red.base};
font-size: ${({ theme }) => theme.typography.size.m2}px;
font-weight: 800;
`;

const PendingInfoText = styled.div`
color: ${({ theme }) => theme.colors.gray.base};
font-size: ${({ theme }) => theme.typography.size.s1}px;
`;

const BasicInfoSection = styled.section`
display: flex;
flex-direction: column;
background-color: ${({ theme }) => theme.colors.white.base};
padding: 20px;
margin-top: 15px;
`;

const OrderNumberText = styled.div`
color: ${({ theme }) => theme.colors.black[300]};
font-size: ${({ theme }) => theme.typography.size.s2}px;
line-height: 16px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
`;

const OrderNumberTitle = styled.span`
color: ${({ theme }) => theme.colors.black.base};
font-size: ${({ theme }) => theme.typography.size.s2}px;
line-height: 16px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
`;

const VerticalLine = styled.div`
margin: 18px 0;
height: 1px;
background-color: ${({ theme }) => theme.colors.gray[500]};
`;

const BasicInfoTimeBox = styled.div`
display: flex;
flex-direction: column;
gap: 13px;
`;

const BasicInfoTimeBoxLine = styled.div`
display: flex;
`;

const BasicInfoGrayTitle = styled.div`
font-size: ${({ theme }) => theme.typography.size.s2}px;
color: ${({ theme }) => theme.colors.gray[800]};
line-height: 16px;
margin-right: 10px;
`;

const BasicInfoTimeText = styled.div`
font-size: ${({ theme }) => theme.typography.size.s2}px;
color: ${({ theme }) => theme.colors.black[200]};
line-height: 16px;
`;

const BasicInfoPriceBox = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-bottom: 25px;
`;

const BasicInfoPriceText = styled.div`
font-size: ${({ theme }) => theme.typography.size.s2}px;
color: ${({ theme }) => theme.colors.black[200]};
font-weight: ${({ theme }) => theme.typography.weight.bold};
line-height: 16px;
`

const BasicInfoButtonBox = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
`;

const RightArrowImg = styled.img`
width: 6px;
height: 8px;
margin-left: 8px;
color: ${({ theme }) => theme.colors.black[100]};
`;

const OrderProcessListSection = styled.section`
`;

const TaskItemBoxWrapper = styled.div<{hide: boolean}>`
display: ${({hide}) => hide ? 'none' : ''};
padding: 10px 20px;
`;

const MoreLoad = styled(Flex)`
width: 120px;
height: 40px;
background-color: ${({ theme }) => theme.colors.white.base};
color: ${({ theme }) => theme.colors.black.base};
font-size: ${({ theme }) => theme.typography.size.s2}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
border-radius: 20px;
margin: 15px 0 25px 0;
box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.10);
cursor: pointer;
`;

const ShoppingSection = styled.section`
margin-bottom: 100px;
`;

const DropDownBoxProductWrapper = styled.div`
display: flex;
flex-direction: column;
padding: 10px 20px;
gap: 10px;
`;

enum SubscriptionDtoStatusTypeEnum {
    Finished = 'finished',
    Starting = 'starting',
    Canceled = 'canceled',
    Pending = 'pending'
};

function membershipTotalCount (productName: string | undefined) {
    if (!productName) {
        return 0;
    }
    return parseInt(productName.split(' ')[productName.split(' ').length - 1].replace(/[^0-9]/g, ''));
};

function membershipStatus(subscriptions: Array<SubscriptionDto> | '') {
    if (subscriptions === ''){
        return false;
    }
    let pendingList: Array<string> = [];
    let canceledList: Array<string> = [];
    let statusTextList: Array<string> = [];

    const pendingProgress = subscriptions.filter(d => d.statusType === SubscriptionDtoStatusTypeEnum.Pending);
    if (pendingProgress && pendingProgress.length > 0) {
        pendingProgress.forEach(d => {
            if (!pendingList.includes(d.targetId)) {
                pendingList = [ ...pendingList, d.targetId];
            }
        });
        statusTextList.push(pendingList.join(', ')+' 일시정지');
    }

    const canceledProgress = subscriptions.filter(d => d.statusType === SubscriptionDtoStatusTypeEnum.Canceled);
    if (canceledProgress && canceledProgress.length > 0) {
        canceledProgress.forEach(d => {
            if (!canceledList.includes(d.targetId)) {
                canceledList = [ ...canceledList, d.targetId];
            }
        });
        statusTextList.push(canceledList.join(', ')+' 취소');
    }
    if (statusTextList) {
        return statusTextList.join(' | ')
    }
    return false;
};

const Memberships = () => {
    const url = useLocation();
    const [ descendingFilter, setDescendingFilter ] = useState<boolean>(true);
    const [ membershipDetail, setMembershipDetail ] = useState<CustomerPurchaseItemDetailDto>();
    const [ rawTaskList, setRawTaskList ] = useState<Array<OrderTaskDto>>([]);
    const [ taskList, setTaskList ] = useState<Array<OrderTaskDto>>([]);
    const [ taskListCount, setTaskListCount ] = useState<number>(5);
    const [ loading, setLoading ] = useState<boolean>(true);

    const taskListSorting = useCallback((list: Array<OrderTaskDto>, descend: boolean) => {
        if (list.length === 0) {
            return [];
        }
        const sortedList = list.sort((a: OrderTaskDto, b: OrderTaskDto) => {
            return moment(descend ? a.createdAt : b.createdAt).isBefore(moment(descend ? b.createdAt : a.createdAt)) ? 1
                : moment(descend ? a.createdAt : b.createdAt).isAfter(moment(descend ? b.createdAt : a.createdAt)) ? -1 : 0
        });
        return sortedList;
    }, []);

    const sortByFilter = useCallback((filterState: boolean) => {
        if (!rawTaskList || rawTaskList.length === 0) {
            return;
        }
        if (filterState === true) {
            setTaskList(taskListSorting(rawTaskList, true));
            setDescendingFilter(true);
            return;
        }
        setTaskList(taskListSorting(rawTaskList, false));
            setDescendingFilter(false);
        return;
    }, [ rawTaskList, taskListSorting ]);

    const getMembershipOrder = useCallback(async (id: string, membershipId: string) => {
        const membershipDetailRs = await repository.getMembershipOrder(id, membershipId);
        if (membershipDetailRs) {
            setMembershipDetail(membershipDetailRs);
        }
    }, []);

    const getSubscriptionTasks = useCallback(async (id: string, membershipId: string) => {
        const membershipTasks = await repository.getMembershipTasks(id, membershipId);
        if (membershipTasks) {
            setRawTaskList(membershipTasks);
            setTaskList(taskListSorting(membershipTasks, true));
        }
    }, [ taskListSorting ]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!url || !url.pathname) {
            return;
        }
        ( async () => {
            try {
                setLoading(true);
                const customerId = getCustomerIdFromPath(url.pathname);
                const membershipId = getMembershipIdFromPath(url.pathname);
                await getMembershipOrder(customerId, membershipId);
                await getSubscriptionTasks(customerId, membershipId);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            };
        })();
    }, [ url, getMembershipOrder, getSubscriptionTasks ]);

    return (
        <WrapperV2>
            <main>
            <Header>
                <Link
                    to={`/customers/${getCustomerIdFromPath(url.pathname)}`}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 70 }}
                >
                    <HeaderLeftContent
                        src={'/images/icons/Home.svg'}
                    />
                </Link>
                <HeaderRightContent>
                    {membershipDetail?.productName
                        ? membershipDetail.subscriptions.find(d => d.count > 1)
                            ? `${membershipDetail?.productName} ${membershipDetail.subscriptions.find(d => d.count > 1)?.count}개`
                            : membershipDetail.productName
                        : '-'
                    }
                </HeaderRightContent>
            </Header>
            { loading
                ? <Mask />
                : <>
                    <BriefInfoSection>
                        <div>
                            <BriefInfoRedText>{`${membershipTotalCount(membershipDetail?.productName) - (membershipDetail?.leftCount ?? 0)}회 완료`}</BriefInfoRedText>
                            <BriefInfoBlackText>{` / ${membershipTotalCount(membershipDetail?.productName)}회`}</BriefInfoBlackText>
                        </div>
                        { membershipStatus(membershipDetail?.subscriptions ?? '') &&
                            <PendingInfoText>{`${membershipStatus(membershipDetail?.subscriptions ?? '')}`}</PendingInfoText>
                        }
                    </BriefInfoSection>
                    <BasicInfoSection>
                        <OrderNumberText>
                            <OrderNumberTitle>주문번호 </OrderNumberTitle>
                            {membershipDetail?.orderNo}
                        </OrderNumberText>
                        <VerticalLine/>
                        <BasicInfoTimeBox>
                            <BasicInfoTimeBoxLine>
                                <BasicInfoGrayTitle>주문시간</BasicInfoGrayTitle>
                                <BasicInfoTimeText>
                                    { membershipDetail?.purchaseDate
                                        ? moment(membershipDetail?.purchaseDate).format('YYYY. MM. DD(dd) a h:mm')
                                        : '-'
                                    }
                                </BasicInfoTimeText>
                            </BasicInfoTimeBoxLine>
                            <BasicInfoTimeBoxLine>
                                <BasicInfoGrayTitle>완료기한</BasicInfoGrayTitle>
                                <BasicInfoTimeText>
                                    { membershipDetail?.subscriptions[0].endDate
                                        ? moment(membershipDetail?.subscriptions[0].endDate).format('YYYY. MM. DD(dd) a h:mm')
                                        : '-'
                                    }
                                </BasicInfoTimeText>
                            </BasicInfoTimeBoxLine>
                            { membershipDetail?.subscriptions.map(d => d.statusType === 'pending'
                                ? <BasicInfoTimeBoxLine>
                                    <BasicInfoGrayTitle>{`${d.targetId} 정지시간`}</BasicInfoGrayTitle>
                                    <BasicInfoTimeText>
                                        { d.pendingDt
                                            ? moment(d.pendingDt).format('YYYY. MM. DD(dd) a h:mm')
                                            : '-'
                                        }
                                    </BasicInfoTimeText>

                                </BasicInfoTimeBoxLine>
                                : <></>
                            )}
                        </BasicInfoTimeBox>
                        <VerticalLine/>
                        <BasicInfoPriceBox>
                            <BasicInfoGrayTitle>결제금액</BasicInfoGrayTitle>
                            <BasicInfoPriceText>{`${membershipDetail?.paymentAmount.toLocaleString() ?? '-'}원`}</BasicInfoPriceText>
                        </BasicInfoPriceBox>
                        <BasicInfoButtonBox>
                            <BoxLinkButton grayButton onClick={() => {
                                window.open('/membership', '_blank', 'noreferrer');
                            }}>
                                멤버십 등급제 시작 안내<RightArrowImg src={'/images/icons/RightLine.svg'} />
                            </BoxLinkButton>
                            <BoxLinkButton grayButton onClick={() => {
                                window.open('/review-event', '_blank', 'noreferrer');
                            }}>
                                리뷰 적립금 이벤트 개편 안내<RightArrowImg src={'/images/icons/RightLine.svg'} />
                            </BoxLinkButton>
                        </BasicInfoButtonBox>
                    </BasicInfoSection>
                    <OrderProcessListSection>
                        <ListTopBar
                            leftText='작업 진행 내역'
                            filterText='작업일자'
                            descendingFilter={descendingFilter}
                            onClick={() => {
                                setTaskListCount(5);
                                sortByFilter(!descendingFilter);
                            }}
                        />
                        { (taskList && taskList.length > 0)
                            ? taskList.map((dayData, index) => (
                                <TaskItemBoxWrapper
                                    hide={index > taskListCount -1}
                                    key={dayData.id}
                                >
                                    <DetailPageTaskItem
                                        item={dayData}
                                    />
                                </TaskItemBoxWrapper>
                            )) : <div style={{ padding: '10px 20px' }}>내역이 없습니다.</div>
                        }
                        { taskList && taskList.length > 0
                            && (taskList.length > taskListCount) &&
                            <Flex>
                                <MoreLoad
                                    onClick={() => {
                                        setTaskListCount(prev => prev + 5);
                                    }}
                                >
                                    내용 더보기
                                </MoreLoad>
                            </Flex>
                        }
                    </OrderProcessListSection>
                    <ShoppingSection>
                        <ListTopBar
                            leftText='상품 둘러보기'
                            onlyLeft
                        />
                        <DropDownBoxProductWrapper>
                            <DropDownBox
                                leftText='패키지 구독 ⭐'
                                open={true}
                            >
                                <ProductDisplay
                                    list={PackageSubscriptionProduct}
                                />
                            </DropDownBox>
                            <DropDownBox
                                leftText='자동 좋아요'
                            >
                                <ProductDisplay
                                    list={AutoLikeProduct}
                                />
                            </DropDownBox>
                            <DropDownBox
                                leftText='한국인 좋아요 팔로우'
                            >
                                <ProductDisplay
                                    list={KoreanLikeFollowProduct}
                                />
                            </DropDownBox>
                            <DropDownBox
                                leftText='외국인 좋아요 팔로우'
                            >
                                <ProductDisplay
                                    list={ForeignerLikeFollowProduct}
                                />
                            </DropDownBox>
                        </DropDownBoxProductWrapper>
                    </ShoppingSection>
                    <ToTheTopButton/>
                </>
            }
            </main>
        </WrapperV2>
    );
};

export default Memberships;