import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Flex from "../components/flex";
import Header from "../components/header";
import WrapperV2 from "../components/wrapperV2";
import repository from "../repository";
import { setAuthCookie } from "../authManager";
import { getCustomerId2 } from '../utils/getCustomerIdFromPath';

const HeadLeftContent = styled.div`
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
`;

const HeaderLeftImage = styled.img`
width: 96px;
height: 22px;
padding: 0 25px;
`;

const LoginContainer = styled.div`
display: flex;
flex-direction: column;
box-sizing: border-box;
align-items: center;
justify-content: center;
padding: 32px 0 0 0;
background-color: ${({ theme }) => theme.colors.white.base};
color: ${({ theme }) => theme.colors.black[200]};
height: 600px;
`;

const LoginSubmit = styled.button`
padding: 8px 12px;
border-radius: 5px;
margin-top: 30px;
background-color: #0896fb;
color: ${({ theme }) => theme.colors.white.base};
`;

const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [ phoneNumber, setPhoneNumber ] = useState<string>();
    const [ errorId, setErrorId ] = useState<string>();
    const [ errorPassword, setErrorPassword ] = useState<string>();

    const onlyPasswordCustomerLogin = useCallback(async (password: string) => {
        if (!phoneNumber) {
            window.alert('링크를 통해 접속해주세요.');
            return;
        }
        const payload = {
            phone: phoneNumber,
            password: password,
        };
        try {
            const rs = await repository.postCustomerLogin(payload);
            if (rs) {
                setAuthCookie(rs.token, rs.customer.id);
                if (!location?.state?.from?.pathname) {
                    const to = `/customers/${rs.customer.id}?phone=${phoneNumber}`
                    navigate(to, { replace: true });
                    return;
                }
                const fromChangePasswordPage = location.state.from.pathname.includes('change-password');
                const to = fromChangePasswordPage
                    ? `/customers/${rs.customer.id}?phone=${phoneNumber}`
                    : location.state.from.pathname + location.state.from.search
                navigate(to, { replace: true });
            }
        } catch (err) {
            window.alert('비밀번호를 확인해주세요.\n 비밀번호를 잃어버리신 경우 고객센터에 문의해주세요.');
        }
    }, [ location, navigate, phoneNumber ]);

    const idPasswordCustomerLogin = useCallback(async (id: string, password: string) => {
        const payload = {
            phone: id,
            password: password,
        };
        try {
            const rs = await repository.postCustomerLogin(payload);
            if (rs) {
                setAuthCookie(rs.token, rs.customer.id);
                if (!location?.state?.from?.pathname) {
                    const to = `/customers/${rs.customer.id}?phone=${id}`
                    navigate(to, { replace: true });
                    return;
                }
                const fromChangePasswordPage = location.state.from.pathname.includes('change-password');
                const to = fromChangePasswordPage
                    ? `/customers/${rs.customer.id}?phone=${id}`
                    : location.state.from.pathname + location.state.from.search
                navigate(to, { replace: true });
            }
        } catch (err) {
            window.alert('아이디 혹은 비밀번호를 확인해주세요.\n 비밀번호를 잃어버리신 경우 고객센터에 문의해주세요.');
        }
    }, [ location, navigate ]);

    useEffect(() => {
        if (!location?.state?.from?.search) {
            return;
        }
        const searchParams = new URLSearchParams(location.state.from.search);
        const phone = searchParams.get('phone');
        if (phone) {
            setPhoneNumber(phone);
        }
    }, [ location ]);

    useEffect(() => {
        // 이벤트 리스너 함수
        const receiveDataFromASite = (event: any) => {
            if (event.origin !== process.env.REACT_APP_DASHBOARD_PAGE_URL) {
                return;
            }

            const { customerToken, customerId } = event.data;
            if (!customerToken || !customerId) {
                return;
            }

            setAuthCookie(customerToken, customerId);
            navigate(`/customers/${customerId}`, { replace: true });
        };

        window.addEventListener('message', receiveDataFromASite);
        return () => {
            window.removeEventListener('message', receiveDataFromASite);
        };
    }, []);

    useEffect(() => {
        if (!location?.state?.from?.pathname) {
            return;
        }

        const customerId = getCustomerId2(location.state.from.pathname);
        if (!customerId || customerId === '..') {
            return;
        }

        const postToParent = (event: any) => {
            if (!window.opener) {
                return;
            }

            const message = {
                type: 'ADMIN_CHECKED',
                customerId,
            };
            window.opener.postMessage(JSON.stringify(message), process.env.REACT_APP_DASHBOARD_PAGE_URL);
        };

        window.addEventListener('load', postToParent);
        return () => {
            window.removeEventListener('load', postToParent);
        };
    }, []);

    const onlyPasswordHandleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //@ts-ignore
        const password: string = event.target.password.value ?? '';
        if (password.length < 4) {
            setErrorPassword('비밀번호는 4자리 이상입니다.');
            return;
        }
        setErrorPassword('');
        await onlyPasswordCustomerLogin(password);
    };

    const idPasswordHandleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //@ts-ignore
        const password: string = event.target.password.value ?? '';
        //@ts-ignore
        const id: string = event.target.id.value ?? '';
        if (id === '' || !id ) {
            setErrorId('아이디를 입력해주세요.');
            return;
        } else if (/^[0-9]*$/.test(id) === false) {
            setErrorId('아이디는 휴대폰 번호입니다.');
            return;
        }
        setErrorId('');
        if (password.length < 4) {
            setErrorPassword('비밀번호는 4자리 이상입니다.');
            return;
        }
        setErrorPassword('');
        await idPasswordCustomerLogin(id, password);
    };

    return (
        <WrapperV2>
            <main>
            <Header>
                <div></div>
                <HeadLeftContent
                    onClick={() => {
                        window.open('https://www.insta-leader.kr/', '_blank', 'noreferrer');
                    }}
                >
                    <HeaderLeftImage
                        src='https://cdn-pro-web-241-106.cdn-nhncommerce.com/amongkr_godomall_com/data/skin/front/moment/img/banner/abb3160f997c0cc36e6737c8aaf417b2_15488.png'
                    />
                </HeadLeftContent>
                <div></div>
            </Header>
            { phoneNumber
                ? <LoginContainer>
                    <form  onSubmit={onlyPasswordHandleSubmit}>
                        <Flex
                            column
                        >
                        <div>비밀번호를 입력하세요.</div>
                        <div style={{ fontSize: 12 }}>기본 비밀번호는 휴대폰 번호입니다.</div>
                        <div style={{ fontSize: 12, color: '#969696' }}>{`예시) 01012341234`}</div>
                        <div>비밀번호</div>
                        <input
                            style={{ margin: '3px 0', fontSize: 16, width: 200, padding: 5 }}
                            type='password'
                            name='password'
                        />
                        <p style={{ color: 'red', margin: 0, fontSize: 12 }}>{errorPassword}</p>
                        <LoginSubmit
                            type='submit'
                        >로그인</LoginSubmit>
                        </Flex>
                    </form>
                </LoginContainer>
                : <LoginContainer>
                    <form  onSubmit={idPasswordHandleSubmit}>
                        <Flex
                            column
                        >
                        <div>아이디와 비밀번호를 입력하세요.</div>
                        <div style={{ fontSize: 12 }}>아이디는 휴대폰 번호</div>
                        <div style={{ fontSize: 12 }}>기본 비밀번호는 휴대폰 번호입니다.</div>
                        <div style={{ fontSize: 12, color: '#969696' }}>{`예시) 아이디 : 01012341234`}</div>
                        <div style={{ fontSize: 12, color: '#969696' }}>{`예시) 비밀번호 : 01012341234`}</div>
                        <div style={{ fontSize: 14, marginTop: 30 }}>
                            <div>아이디</div>
                            <input
                                style={{ margin: '3px 0', fontSize: 16, width: 200, padding: 5 }}
                                type='id'
                                name='id'
                            />
                            <p style={{ color: 'red', margin: 0, fontSize: 12 }}>{errorId}</p>
                            <div>비밀번호</div>
                            <input
                                style={{ margin: '3px 0', fontSize: 16, width: 200, padding: 5 }}
                                type='password'
                                name='password'
                            />
                            <p style={{ color: 'red', margin: 0, fontSize: 12 }}>{errorPassword}</p>
                        </div>
                        <LoginSubmit
                            type='submit'
                        >로그인</LoginSubmit>
                        </Flex>
                    </form>
                </LoginContainer>
            }
            </main>
        </WrapperV2>
    );
};

export default Login;