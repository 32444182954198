import { HeartOutlined, UserAddOutlined } from "@ant-design/icons";
import moment from "moment";
import 'moment/locale/ko';
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { OrderTaskDto } from "../utils/dummyData";
import BoxLinkButton from "./boxLinkButton";
import Flex from "./flex";
import ProgressBar from "./progressBar";

const OrderItemBox = styled.div`
display: flex;
flex-direction: column;
background-color: ${({ theme }) => theme.colors.white.base};
padding: 15px;
border-radius: 5px;
`;

const ProgressBarBox = styled.div`
margin-bottom: 15px;
`;

const TopTitle = styled.div`
color: ${({ theme }) => theme.colors.black[100]};
font-size: ${({ theme }) => theme.typography.size.s2}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
line-height: 16px;
`;

const VerticalLine = styled.div`
height: 10px;
width: 1px;
background-color: ${({ theme }) => theme.colors.gray[500]};
margin: 0 8px;
`;

const RightText = styled.div`
color: ${({ theme }) => theme.colors.gray[800]};
font-size: ${({ theme }) => theme.typography.size.s1}px;
`;

const ProductNameText = styled.div<{ finished: boolean }>`
color: ${({ theme, finished }) => finished ? theme.colors.black[200] : theme.colors.black[300]};
font-size: ${({ theme }) => theme.typography.size.s1}px;
line-height: 14px;
`;

const YellowBox = styled.div`
display: flex;
flex-direction: row;
border-radius: 5px;
background-color: #FFF2D1;
padding: 5px;
`;

const BonusImg = styled.img`
margin-right: 3px;
`;

const BonusText = styled.div`
color: ${({ theme }) => theme.colors.red.base};
font-size: ${({ theme }) => theme.typography.size.s1}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
`;

const CanceledText = styled.div`
color: ${({ theme }) => theme.colors.red.base};
font-size: ${({ theme }) => theme.typography.size.s1}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
`;

const InstaIdText = styled.div`
color: ${({ theme }) => theme.colors.black[300]};
font-size: ${({ theme }) => theme.typography.size.s1}px;
line-height: 14px;
margin-top: 4px;
`;

export enum ITaskType {
    Like = 'like',
    LikeReels = 'like_reels',
    Follow = 'follow'
};

function getOrderTaskType(type: ITaskType) {
    if (type === ITaskType.Like || type === ITaskType.LikeReels) {
        return '좋아요';
    }
    if (type === ITaskType.Follow) {
        return '팔로워';
    }
    else {
        return '.....';
    }
};

interface IDetailPageTaskItem {
    item: OrderTaskDto;
};

const DetailPageTaskItem = (props: IDetailPageTaskItem) => {
    return (
        <OrderItemBox>
            { props.item.taskStatus !== 'finished'
                ? props.item.taskStatus === 'impossible' || props.item.taskStatus === 'temporary_paused'
                    ? <CanceledText>취소된 작업입니다.</CanceledText>
                    : <ProgressBarBox>
                        <ProgressBar
                            count={props.item.progressCnt}
                            totalCount={props.item.displayingCnt}
                        />
                    </ProgressBarBox>
                : <></>
            }
            <Flex
                justifyContent='space-between'
            >
                <Flex>
                    <TopTitle>{moment(props.item.createdAt).format('YY. MM. DD dd')}</TopTitle>
                    <VerticalLine />
                    <TopTitle>{moment(props.item.createdAt).format('HH:mm:ss')}</TopTitle>
                </Flex>
                { props.item.taskStatus === 'finished' &&
                    <RightText>완료</RightText>
                }
            </Flex>
            <Flex
                style={{ marginTop: 5, marginBottom: 8 }}
                justifyContent='space-between'
            >
                <div>
                <ProductNameText
                    finished={props.item.taskStatus === 'finished'}
                >
                    {props.item.productName}
                    { getOrderTaskType(props.item.taskType) === '좋아요' && <HeartOutlined style={{ color: '#E1306C', marginLeft: 5 }} /> }
                    { getOrderTaskType(props.item.taskType) === '팔로워' && <UserAddOutlined style={{ color: '#0095f6', marginLeft: 5 }} /> }
                </ProductNameText>
                <InstaIdText>{props.item.targetId}</InstaIdText>
                </div>
                { props.item.progressCnt > props.item.displayingCnt &&
                    <YellowBox>
                        <BonusImg
                            src={'/images/icons/Bonus.svg'}
                        />
                        <BonusText>{`${props.item.progressCnt - props.item.displayingCnt}회 +`}</BonusText>
                    </YellowBox>
                }
            </Flex>
            { getOrderTaskType(props.item.taskType) === '팔로워' &&
                <div style={{ marginBottom: 10 }}>
                    <NavLink to='/notice/flagged-account'
                        style={{ textDecoration: 'underline', color: 'red', fontSize: 12 }}
                    >
                        {'혹시 작업이 완료됐는데도 팔로워가 오르지 않았나요?'}
                    </NavLink>
                </div>
            }
            <a
                href={props.item.taskType.includes('follow')
                    ? `https://www.instagram.com/${props.item.targetId}`
                    : props.item.taskType.includes('reels')
                        ? `https://www.instagram.com/reel/${props.item.targetUrl}`
                        : `https://www.instagram.com/p/${props.item.targetUrl}`
                }
                target='_blank'
                rel='noreferrer noopener'
            >
                <BoxLinkButton>
                    {props.item.taskType === 'follow' ? '작업 적용된 인스타그램' : '작업 적용된 인스타그램 게시글'}
                </BoxLinkButton>
            </a>
        </OrderItemBox>
    )
};

export default DetailPageTaskItem;