import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
// @ts-ignore
import { Fade } from 'react-reveal';
import CountUp from 'react-countup';
import Flex from '../components/flex';
import getOrderData from '../utils/getOrderData';

const Between = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

const KakaoButton = styled.button`
background: #006AFF;
padding: 12px 16px;
border-radius: 300px;
color: #ffffff;
z-index: 100;
border: none;
font-size: 20px;
font-weight: 800;
min-width: 250px;
cursor: pointer;
transition: all 0.2s ease;

:hover {
    filter: brightness(80%);
}
`;

const KakaoButtonSmall = styled.button`
color: #9c9c9c;
background-color: transparent;
border: none;
font-size: 16px;
font-weight: 800;
cursor: pointer;

:hover {
    color: #424242;
}
`;

const Section = styled.section<{ color?: string }>`
padding: 100px 0;
background-color: ${({ theme, color }) => color ? color : theme.colors.white.base};
box-sizing: border-box;
`;

const Container = styled.div`
width: 100%;
margin: 0 auto;
text-align: center;
`;

const SectionTitle = styled.h1<{ color?: string }>`
font-size: 18px;
font-weight: 800;
margin-top: 0;
margin-bottom: 40px;
line-height: 1.4em;
${props => props.color && `color: ${props.color}`};
`;


const Highlight = styled.span`
font-weight: 800;
box-shadow: inset 0 -10px 0 rgb(247, 226, 75);
`;

const RecommendText = styled.span`
font-size: 18px;
font-weight: 600;
margin: 0 0 10px 0;
margin-bottom: 20px;
`;

const AppDownloadImage = styled.img`
border-radius: 16px;
transition: background-color 0.2s ease;

:hover {
    background-color: #ffffff80;
}
`;

const FooterAppDownloadImage = styled(AppDownloadImage)`
border-radius: 10px;

:hover {
    background-color: #00000020;
}
`;

const NewSection = styled.section`
width: 100%;
/* max-width: 1080px; */
/* margin: auto; */
`;

const Link = styled.a`
text-decoration: none;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`;

const HomeMobile = () => {
    // @ts-ignore
    const theme = useContext(ThemeContext);

    const addKakaoChannel = () => {
        // @ts-ignore
        Kakao.Channel.addChannel({
            channelPublicId: '_ZZZexj'
        });
    };

    return (
        <>
            <main style={{
                maxWidth: 1080,
                margin: 'auto',
                background: '#f5f5f5',
            }}>
                <NewSection>
                    <img
                        src='images/home/section_1.png'
                        width='100%'
                        height='100%'
                        style={{ display: 'block' }}
                    />
                </NewSection>
                <NewSection style={{ height: 120 }}>
                    <Flex style={{ height: '100%' }}>
                        <KakaoButton onClick={addKakaoChannel}>
                            <Flex gap={20}>
                                <div style={{ fontSize: 20, fontWeight: 'bold' }}>더 알아보기</div>
                            </Flex>
                        </KakaoButton>
                    </Flex>
                </NewSection>
                <NewSection>
                    <img
                        src='images/home/section_3.png'
                        width='100%'
                        height='100%'
                        style={{ display: 'block' }}
                    />
                </NewSection>
                <NewSection>
                    <img
                        src='images/home/section_2.png'
                        width='100%'
                        height='100%'
                        style={{ display: 'block' }}
                    />
                </NewSection>
                <NewSection>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        height: 120,
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        background: '#f5f5f5',
                        textAlign: 'center',
                    }}>
                        <Fade>
                            <Flex column>
                                <div style={{ fontWeight: 700, fontSize: 16, marginBottom: 20 }}>진행중인 주문</div>
                                <CountUp
                                    end={Math.floor(getOrderData().진행중주문 / 100) * 100}
                                    duration={2}
                                    separator=','
                                    suffix='+'
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 800,
                                        color: '#006AFF',
                                    }}
                                />
                            </Flex>
                            <Flex column>
                                <div style={{ fontWeight: 700, fontSize: 16, marginBottom: 20 }}>완료된 주문</div>
                                <CountUp
                                    end={Math.floor(getOrderData().완료된주문 / 100) * 100}
                                    separator=','
                                    suffix='+'
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 800,
                                        color: '#006AFF',
                                    }}
                                />
                            </Flex>
                            <Flex column>
                                <div style={{ fontWeight: 700, fontSize: 16, marginBottom: 20 }}>누적 회원 수</div>
                                <CountUp
                                    end={Math.floor(getOrderData().누적회원수 / 100) * 100}
                                    separator=','
                                    suffix='+'
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 800,
                                        color: '#006AFF',
                                    }}
                                />
                            </Flex>
                        </Fade>
                    </div>
                </NewSection>
                <Section color={`#242424`}>
                    <Container>
                        <Flex style={{ marginBottom: 30, gap: 10 }}>
                            <SectionTitle style={{ color: '#ffffff', marginBottom: 0 }}>편리하게 카카오톡으로 문의하세요.</SectionTitle>
                            <button onClick={addKakaoChannel} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                <Flex style={{ background: 'rgb(247, 226, 75)', width: 40, height: 40, borderRadius: 1000, }}>
                                    <img
                                        src='/images/kakao-talk.png'
                                        width='24px'
                                        height='24px'
                                    />
                                </Flex>
                            </button>
                        </Flex>
                        <KakaoButton onClick={addKakaoChannel}>
                            더 알아보기
                        </KakaoButton>
                    </Container>
                </Section>
            </main>
            <footer
                color={theme.colors.gray[400]}
                style={{ padding: '40px 0' }}
            >
                <Container>
                    <div style={{ color: theme.colors.gray.base, fontSize: 12 }}>
                        <div>(주) 어몽</div>
                        <div>사업자 등록번호: 897-87-01966 | 서울특별시 성북구 삼양로 22, 202호</div>
                        <div>통신판매업 2021-서울성북-1931 | 호스팅서비스제공자 (주) 어몽</div>
                        <div>Copyright © Among Inc. All Rights Reserved.</div>
                        <div>대표: 류정원, 함승주</div>
                    </div>
                </Container>
            </footer>
        </>
    );
};

export default HomeMobile;