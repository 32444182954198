import { FormEvent, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Flex from "../components/flex";
import Header from "../components/header";
import WrapperV2 from "../components/wrapperV2";
import repository from "../repository";
import getCustomerIdFromPath from "../utils/getCustomerIdFromPath";
import { clearAuthCookie } from "../authManager";

const HeadRightContent = styled.div`
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
padding: 0 25px;
`;

const HeaderLeftContent = styled.img`
width: 20px;
height: 20px;
`;

const HeaderRightImage = styled.img`
width: 96px;
height: 22px;
`;

const ChangePasswordContainer = styled.div`
display: flex;
flex-direction: column;
box-sizing: border-box;
padding: 62px 0 50px 0;
background-color: ${({ theme }) => theme.colors.white.base};
color: ${({ theme }) => theme.colors.black[200]};
height: 600px;
`;

const ChangePasswordSubmit = styled.button`
padding: 8px 12px;
border-radius: 5px;
margin-top: 30px;
background-color: #0896fb;
color: ${({ theme }) => theme.colors.white.base};
`;

const ChangePassword = () => {
    const url = useLocation();
    const navigate = useNavigate();

    const changePassword = useCallback(async (
        newPassword1: string,
        newPassword2: string ) => {
        try {
            if (newPassword1.length < 4 || newPassword2.length < 4) {
                window.alert('비밀번호는 4자리 이상이여야 합니다.');
                return;
            }
            if (newPassword1 !== newPassword2) {
                window.alert('새 비밀번호가 일치하지 않습니다. 새 비밀번호를 다시 입력하세요');
                return;
            }
            const payload = {
                newPassword: newPassword1,
            };
            if (url && url.pathname) {
                const rs = await repository.changePassword(payload);
                if (rs) {
                    window.alert('변경된 비밀번호로 다시 로그인하세요');
                    clearAuthCookie(getCustomerIdFromPath(url.pathname));
                    navigate('/login' , { replace: true, state: { from: url }});
                }
            }
        } catch (err) {
            window.alert('서버 오류');
        }
    }, [ navigate, url ]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //@ts-ignore
        const newPassword1: string = event.target.newPassword1.value ?? '';
        //@ts-ignore
        const newPassword2: string = event.target.newPassword2.value ?? '';
        await changePassword(newPassword1, newPassword2);
    };

    return (
        <WrapperV2>
            <main>
            <Header>
                <Link
                    to={`/customers/${getCustomerIdFromPath(url.pathname)}`}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 70 }}
                >
                    <HeaderLeftContent
                        src={'/images/icons/Home.svg'}
                    />
                </Link>
                <HeadRightContent
                    onClick={() => {
                        window.open('https://www.insta-leader.kr/', '_blank', 'noreferrer');
                    }}
                >
                    <HeaderRightImage
                        src='https://cdn-pro-web-241-106.cdn-nhncommerce.com/amongkr_godomall_com/data/skin/front/moment/img/banner/abb3160f997c0cc36e6737c8aaf417b2_15488.png'
                    />
                </HeadRightContent>
                <div style={{ width: 70 }}></div>
            </Header>
                <ChangePasswordContainer>
                    <form  onSubmit={handleSubmit}>
                        <Flex
                            column
                        >
                        <div style={{ fontSize: 18, fontWeight: 700 }}>비밀번호 변경</div>
                        <div style={{ fontSize: 12, color: '#969696' }}>기본 비밀번호는 휴대폰 번호입니다.</div>
                        <div style={{ fontSize: 12, color: '#969696' }}>{`예시) 01012341234`}</div>
                        <div style={{ fontSize: 14, marginTop: 60 }}>
                            <div>새 비밀번호</div>
                            <input
                                style={{ margin: '3px 0', fontSize: 16, width: 200, padding: 5 }}
                                type='password'
                                name='newPassword1'
                            />
                            <div>새 비밀번호 재입력</div>
                            <input
                                style={{ margin: '3px 0', fontSize: 16, width: 200, padding: 5 }}
                                type='password'
                                name='newPassword2'
                            />
                        </div>
                        <ChangePasswordSubmit
                            type='submit'
                        >비밀번호 변경</ChangePasswordSubmit>
                        </Flex>
                    </form>
                </ChangePasswordContainer>
            </main>
        </WrapperV2>
    );
};

export default ChangePassword;