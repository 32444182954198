import styled from "styled-components";

const Wrapper = styled.div`
display: flex;
justify-content: center;
`;

const Container = styled.div`
width: -moz-available;
width: -webkit-fill-available;
width: fill-available;
min-height: 100vh;
background-color: #f4f4f4;
box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.15);
display: block;
padding-top: 62px;
max-width: 600px;
box-sizing: border-box;
`;

interface IWrapperV2 {
    children: React.ReactNode;
}

const WrapperV2 = (props: IWrapperV2) => {

    return (
        <Wrapper>
            <Container>
                {props.children}
            </Container>
        </Wrapper>
    )
};

export default WrapperV2;