import styled from "styled-components";
import { OrderTaskDto } from "../utils/dummyData";
import DetailPageTaskItem from "./detailPageTaskItem";

const TaskItemBox = styled.div`
`;

const HorizontalLine = styled.div<{ hide: boolean }>`
margin: 0 15px;
height: 1px;
background-color: ${({ theme }) => theme.colors.gray[500]};
display: ${({ hide }) => hide ? 'none' : ''};
`;

interface IDropDownTaskItem {
    itemList: Array<OrderTaskDto>;
};

const DropDownTaskItem = (props: IDropDownTaskItem) => {
    return (
        <TaskItemBox>
            { props.itemList.map((item, index) => (
                <div key={item.id}>
                <DetailPageTaskItem
                    item={item}
                />
                <HorizontalLine hide={index === props.itemList.length -1}/>
                </div>
            ))
            }
        </TaskItemBox>
    )
};

export default DropDownTaskItem;