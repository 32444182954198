import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../components/header";
import ListTopBar from "../components/listTopBar";
import MyPageOrderItem from "../components/myPageOrderItem";
import Flex from '../components/flex';
import WrapperV2 from "../components/wrapperV2";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import repository from "../repository";
import { CustomerDto, CustomerPurchaseItemDto } from "../apiTypes";
import Mask from "../components/Mask";
import { clearAuthCookie } from "../authManager";
import getCustomerIdFromPath from "../utils/getCustomerIdFromPath";
import ToTheTopButton from "../components/toTheTopButton";

const MenuWrapper = styled.div<{ drawerOpen: boolean }>`
display: ${({ drawerOpen }) => drawerOpen ? 'flex' : 'none'};
position: fixed;
top: 0;
width: -moz-available;
width: -webkit-fill-available;
width: fill-available;
max-width: 600px;
min-height: 100vh;
z-index: 150;
`;

const MenuRightBox = styled.div`
display: flex;
flex-direction: column;
width: 30%;
height: 100vh;
background-color: rgba(0,0,0,0.5);
`

const MenuLeftBox = styled.div`
flex: 1;
display: flex;
flex-direction: column;
background-color: white;
`;

const MenuRightTop = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;

const MenuRightImage = styled.img`
cursor: pointer;
width: 27px;
height: 27px;
padding: 25px 20px;
`;

const MenuRightContent = styled.div`
display: flex;
flex: 1;
flex-direction: column;
justify-content: space-between;
`;

const NeedChangePasswordText = styled.div`
color: ${({ theme }) => theme.colors.white.base};
background-color: ${({ theme }) => theme.colors.red.base};
font-size: ${({ theme }) => theme.typography.size.m1}px;
margin-top: 10px;
margin-bottom: 20px;
padding: 10px 20px;
`;

const ChangePasswordButton = styled.div`
display: flex;
align-items: center;
text-align: left;
justify-content: space-between;
font-size: ${({ theme }) => theme.typography.size.m1}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
color: ${({ theme }) => theme.colors.gray[800]};
padding: 10px 30px;
`;

const LogoutButton = styled.button`
display: flex;
align-items: center;
text-align: left;
/* justify-content: space-between; */
font-size: ${({ theme }) => theme.typography.size.s1}px;
cursor: pointer;
color: ${({ theme }) => theme.colors.gray[800]};
margin-bottom: 200px;
padding: 10px 30px;
background: inherit;
border: none;
box-shadow: none;
border-radius: 0;
overflow: visible;
`;

const RightImg = styled.img`
margin-left: 5px;
`;

const HeaderRightContent = styled.div`
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
padding: 0 25px;
`;

const HeaderLeftImage = styled.img`
width: 96px;
height: 22px;
`;

const HeaderLeftContent = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 5px;
color: ${({ theme }) => theme.colors.black[300]};
font-size: ${({ theme }) => theme.typography.size.m1}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
user-select: none;
cursor: pointer;
padding: 0 25px;
`;

const HorizontalLine = styled.div`
background-color: ${({ theme }) => theme.colors.gray.base};
height: 2px;
width: 20px;
`;

const BriefInfoSection = styled.section`
box-sizing: border-box;
background-color: ${({ theme }) => theme.colors.white.base};
padding: 32px 20px 20px 20px;
font-size: ${({ theme }) => theme.typography.size.m2}px;
color: ${({ theme }) => theme.colors.black[200]};
line-height: 32px;
`;

const BriefInfoNameText = styled.span`
color: ${({ theme }) => theme.colors.black[200]};
font-size: ${({ theme }) => theme.typography.size.m2}px;
font-weight: 800;
`;

const BriefInfoRedText = styled.span`
color: ${({ theme }) => theme.colors.red.base};
font-size: ${({ theme }) => theme.typography.size.m2}px;
font-weight: 800;
`;

const OrderListNav = styled.nav`
display: flex;
flex-direction: row;
background-color: ${({ theme }) => theme.colors.white.base};
height: 36px;
padding: 0 20px;
border-bottom: 1px ${({ theme }) => theme.colors.gray[500]} solid;
`;

const OrderListNavBox = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: center;
`;

const OrderListNavButton = styled.div<{ selected: boolean }>`
position: absolute;
cursor: pointer;
user-select: none;
margin: 20px;
color: ${({ theme, selected }) => selected ? theme.colors.black[100] : theme.colors.gray[600]};
font-size: ${({ theme }) => theme.typography.size.m1}px;
`;

const OrderListNavUnderLine = styled.div<{ selected: boolean }>`
position: relative;
top: 18px;
background-color: ${({ theme, selected }) => selected && theme.colors.black[200]};
height: 1px;
width: 90px;
`;

const OrderListSection = styled.section`
padding: 0 0 100px 0;
`;

const OrderListContent = styled.ul`
display: flex;
flex-direction: column;
padding: 0 20px;
gap: 15px;
`;

const OrderListContentBox = styled.li<{ hide: boolean }>`
display: ${({ hide }) => hide ? 'none' : ''};
`

const MoreLoad = styled(Flex)`
width: 120px;
height: 40px;
background-color: ${({ theme }) => theme.colors.white.base};
color: ${({ theme }) => theme.colors.black.base};
font-size: ${({ theme }) => theme.typography.size.s2}px;
font-weight: ${({ theme }) => theme.typography.weight.bold};
border-radius: 20px;
margin: 15px 0 25px 0;
box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.10);
cursor: pointer;
`;

const NeedChangePasswordAlert = styled.div`
color: ${({ theme }) => theme.colors.white.base};
background-color: ${({ theme }) => theme.colors.red.base};
padding: 20px 10px 10px 10px;
text-align: center;
border-bottom: 1px #dddddd solid;
`;

const CustomerPage = () => {
    const url = useLocation();
    const navigate = useNavigate();
    const [orderListNavIndex, setOrderListNavIndex] = useState<number>(0);
    const [descendingFilter, setDescendingFilter] = useState<boolean>(false);
    const [customerInfo, setCustomerInfo] = useState<CustomerDto>();
    const [orderList, setOrderList] = useState<Array<CustomerPurchaseItemDto>>([]);
    const [inProgressList, setInProgressList] = useState<Array<CustomerPurchaseItemDto>>([]);
    const [completedList, setCompletedList] = useState<Array<CustomerPurchaseItemDto>>([]);
    const [orderListCount, setOrderListCount] = useState<[number, number]>([5, 5]);
    const [loading, setLoading] = useState<boolean>(true);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const orderListSorting = useCallback((list: Array<CustomerPurchaseItemDto>, descend: boolean) => {
        if (descend) {
            return list.sort((a: CustomerPurchaseItemDto, b: CustomerPurchaseItemDto) => {
                return moment(a.purchaseDate) < moment(b.purchaseDate) ? 1
                    : moment(a.purchaseDate) > moment(b.purchaseDate) ? -1 : 0
            });
        }
        return list.sort((a: CustomerPurchaseItemDto, b: CustomerPurchaseItemDto) => {
            return moment(a.purchaseDate) > moment(b.purchaseDate) ? 1
                : moment(a.purchaseDate) < moment(b.purchaseDate) ? -1 : 0
        });
    }, []);

    const sortByFilter = useCallback((filterState: boolean) => {
        if (!orderList || orderList.length === 0) {
            return;
        }
        if (filterState === true) {
            setOrderList(prev => orderListSorting(prev, true));
            setDescendingFilter(true);
            return;
        }
        setOrderList(prev => orderListSorting(prev, false));
        setDescendingFilter(false);
        return;
    }, [orderList, orderListSorting]);

    const loadMore = useCallback((index: number) => {
        if (index === 0) {
            setOrderListCount(prev => [prev[0] + 5, prev[1]]);
        }
        if (index === 1) {
            setOrderListCount(prev => [prev[0], prev[1] + 5]);
        }
    }, []);

    const logOut = useCallback(() => {
        if (window.confirm('로그아웃 하시겠습니까?')) {
            if (url && url.pathname) {
                clearAuthCookie(getCustomerIdFromPath(url.pathname));
                navigate('/login', {
                    replace: true,
                    state: { from: url }
                });
            }
        }
    }, [url, navigate]);

    useEffect(() => {
        if (orderListNavIndex === 0) {
            setOrderList(orderListSorting(inProgressList, descendingFilter));
            return;
        }
        setOrderList(orderListSorting(completedList, descendingFilter));
    }, [orderListNavIndex, descendingFilter]);

    useEffect(() => {
        if (!url || !url.pathname) {
            return;
        }
        (async () => {
            try {
                setLoading(true);
                const me = await repository.getMe();
                setCustomerInfo(me);
                const progressList = await repository.getInProgressList(getCustomerIdFromPath(url.pathname));
                setInProgressList(progressList);
                setOrderList(orderListSorting(progressList, descendingFilter));
                const completedList = await repository.getCompletedList(getCustomerIdFromPath(url.pathname));
                setCompletedList(completedList);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        })();
    }, [url]);

    return (
        <WrapperV2>
            <main>
            <MenuWrapper drawerOpen={drawerOpen}>
                <MenuLeftBox>
                    <MenuRightTop>
                        <div style={{ fontSize: 16, fontWeight: 700, padding: '25px 20px' }}>설정</div>
                        <MenuRightImage
                            onClick={() => {setDrawerOpen(false)}}
                            src={'/images/icons/XIcon.svg'}
                        />
                    </MenuRightTop>
                    <MenuRightContent>
                        <div>
                            { customerInfo?.needChangePassword
                                ? <NeedChangePasswordText>※ 비밀번호 변경이 필요합니다.</NeedChangePasswordText>
                                : <></>
                            }
                            <Link
                                to={`/customers/${customerInfo?.id}/change-password`}
                            >
                                <ChangePasswordButton>
                                    비밀번호 변경하기
                                    <RightImg
                                        src={'/images/icons/RightLine.svg'}
                                    />
                                </ChangePasswordButton>
                            </Link>
                        </div>
                        <div>
                        <LogoutButton
                            onClick={() => {
                                logOut();
                            }}
                        >
                            {'로그아웃 >'}
                        </LogoutButton>
                        </div>
                    </MenuRightContent>
                </MenuLeftBox>
                <MenuRightBox
                    onClick={() => {setDrawerOpen(false)}}
                />
            </MenuWrapper>
                <Header>
                    <HeaderLeftContent
                        onClick={() => {
                            setDrawerOpen(true);
                        }}
                    >
                        <HorizontalLine /><HorizontalLine /><HorizontalLine />
                    </HeaderLeftContent>
                    <HeaderRightContent
                        onClick={() => {
                            window.open('https://www.insta-leader.kr/', '_blank', 'noreferrer');
                        }}
                    >
                        <HeaderLeftImage
                            src='https://cdn-pro-web-241-106.cdn-nhncommerce.com/amongkr_godomall_com/data/skin/front/moment/img/banner/abb3160f997c0cc36e6737c8aaf417b2_15488.png'
                        />
                    </HeaderRightContent>
                    <div style={{ width: 70 }}></div>
                </Header>
                {loading
                    ? <Mask />
                    : <>
                        { customerInfo?.needChangePassword
                            ? <NeedChangePasswordAlert>비밀번호를 변경해주세요</NeedChangePasswordAlert>
                            : <></>
                        }
                        <BriefInfoSection>
                            <BriefInfoNameText>{customerInfo?.phone ?? '-'}</BriefInfoNameText> 님은 총 <BriefInfoRedText>{inProgressList.length ?? '-'}건 진행 중</BriefInfoRedText>이고,
                            <br />총 {completedList.length ?? '-'}건 진행 완료되었습니다.
                        </BriefInfoSection>
                        <OrderListNav>
                            <OrderListNavBox>
                                <OrderListNavButton
                                    selected={orderListNavIndex === 0}
                                    onClick={() => {
                                        setOrderListNavIndex(0);
                                    }}
                                >진행중인 내용</OrderListNavButton>
                                <OrderListNavUnderLine selected={orderListNavIndex === 0} />
                            </OrderListNavBox>
                            <OrderListNavBox>
                                <OrderListNavButton
                                    selected={orderListNavIndex === 1}
                                    onClick={() => {
                                        setOrderListNavIndex(1);
                                    }}
                                >진행 완료</OrderListNavButton>
                                <OrderListNavUnderLine selected={orderListNavIndex === 1} />
                            </OrderListNavBox>
                        </OrderListNav>
                        <OrderListSection>
                            <ListTopBar
                                leftText={`총 ${orderList.length ?? '-'}건`}
                                filterText='구매일자'
                                descendingFilter={descendingFilter}
                                onClick={() => {
                                    setOrderListCount([5, 5]);
                                    sortByFilter(!descendingFilter);
                                }}
                            />
                            <OrderListContent>
                                {orderList && orderList.length > 0
                                    ? orderList.map((item, index) =>
                                        <OrderListContentBox
                                            hide={index > orderListCount[orderListNavIndex] - 1}
                                            key={`${item.id}`}
                                        >
                                            <MyPageOrderItem
                                                item={item}
                                                to={`${item.itemType}s/${item.id}`}
                                                completed={orderListNavIndex === 1}
                                            />
                                        </OrderListContentBox>
                                    ) : <div>내역이 없습니다.</div>
                                }
                                {orderList && orderList.length > 0
                                    && (orderList.length > orderListCount[orderListNavIndex]) &&
                                    <Flex>
                                        <MoreLoad
                                            onClick={() => {
                                                loadMore(orderListNavIndex);
                                            }}
                                        >
                                            내용 더보기
                                        </MoreLoad>
                                    </Flex>
                                }
                            </OrderListContent>
                            <ToTheTopButton/>
                        </OrderListSection>
                    </>
                }
            </main>
        </WrapperV2>
    );
};

export default CustomerPage;