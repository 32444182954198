
export interface IProductDisplayList {
    url: string;
    src: string;
};

export const PackageSubscriptionProduct: Array<IProductDisplayList> = [
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000025',
        src: '/images/products/packages/silsok.png',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000026',
        src: '/images/products/packages/mongdang.png',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000016',
        src: '/images/products/subscription_likes/30일좋아요50.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000017',
        src: '/images/products/subscription_likes/30일좋아요100.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000023',
        src: '/images/products/subscription_likes/데일리좋아요50_4.png',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000024',
        src: '/images/products/subscription_likes/데일리좋아요100_4.png',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000029',
        src: '/images/products/subscription_likes/데일리좋아요250.png',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000021',
        src: '/images/products/subscription_likes/데일리좋아요500.png',
    },
];

export const KoreanLikeFollowProduct: Array<IProductDisplayList> = [
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000001',
        src: '/images/products/korean_followers/한국인팔로워50.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000008',
        src: '/images/products/korean_likes/한국인좋아요50.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000009',
        src: '/images/products/korean_likes/한국인좋아요100.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000000',
        src: '/images/products/korean_followers/한국인팔로워250.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000002',
        src: '/images/products/korean_followers/한국인팔로워500.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000010',
        src: '/images/products/korean_likes/한국인좋아요500.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000003',
        src: '/images/products/korean_followers/한국인팔로워1000.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000011',
        src: '/images/products/korean_likes/한국인좋아요1000.jpg',
    },
];

export const ForeignerLikeFollowProduct: Array<IProductDisplayList> = [
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000004',
        src: '/images/products/foreigner_followers/외국인팔로워100.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000005',
        src: '/images/products/foreigner_followers/외국인팔로워250.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000006',
        src: '/images/products/foreigner_followers/외국인팔로워500.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000012',
        src: '/images/products/foreigner_likes/외국인좋아요100.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000007',
        src: '/images/products/foreigner_followers/외국인팔로워1000.jpg',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000019',
        src: '/images/products/foreigner_likes/실제계정외국인500.png',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000020',
        src: '/images/products/foreigner_likes/실제계정외국인좋아요1000.png',
    },
];

export const AutoLikeProduct: Array<IProductDisplayList> = [
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000031',
        src: '/images/products/auto_likes/자동좋아요50_10.png',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000034',
        src: '/images/products/auto_likes/자동좋아요100_10.png',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000032',
        src: '/images/products/auto_likes/자동좋아요50_30.png',
    },
    {
        url: 'https://insta-leader.kr/goods/goods_view.php?goodsNo=1000000035',
        src: '/images/products/auto_likes/자동좋아요100_30.png',
    },
];